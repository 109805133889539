export const MultiLanguageCode = {
  SITE_TITLE: 'SITE_TITLE',
  PASSWORD: 'PASSWORD',
  LOGIN: 'LOGIN',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  PRIVACY_POLICY_HEADER: 'PRIVACY_POLICY_HEADER',
  CONTACT_US: 'CONTACT_US',
  COPYRIGHT_TEXT: 'COPYRIGHT_TEXT',
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  TERMS_LINK: 'termsLink',
  TERMS_TEXT: 'termsText',
  CONTACTUS_HEADER: 'CONTACTUS_HEADER',
  CONTACTUS_BODY: 'CONTACTUS_BODY',
  CANCEL_BUTTON: 'CANCEL_BUTTON',
  SUBMIT: 'SUBMIT',
  CONTACTUS_SUCCESS: 'CONTACTUS_SUCCESS',
  EMAIL_FAILED: 'EMAIL_FAILED',
  ANNOUNCEMENTS_VDP: 'ANNOUNCEMENTS_VDP',
  OPEN_RECALL_CLEARING_MESSAGE: 'OPEN_RECALL_CLEARING_MESSAGE',
  OPEN_RECALL_DETAILS: 'OPEN_RECALL_DETAILS',
  OPEN_RECALL_EFFECTIVE_DATE: 'OPEN_RECALL_EFFECTIVE_DATE',
  OPEN_RECALL_CAMPAIGN_ID: 'OPEN_RECALL_CAMPAIGN_ID',
  AS_IS_100K: 'AS_IS_100K',
  TMU_REASON: 'TMU_REASON',
  COMMENT_LINK: 'COMMENT_LINK',
  ANNOUNCED_CONDITIONS: 'ANNOUNCED_CONDITIONS',
  FRAME_DAMAGE: 'FRAME_DAMAGE',
  MILEAGE: 'MILEAGE',
  DEFECT_DATE: 'DEFECT_DATE',
  DEFECT_DESCRIPTION: 'DEFECT_DESCRIPTION',
  DEFECT_CODE: 'DEFECT_CODE',
  SELECT_ALL: 'SelectAll',
  CLEAR_ALL: 'CLEARALL',
  HIGH_VOLTAGE_BATTERY_LIMITED_WARRANTY: 'HIGH_VOLTAGE_BATTERY_LIMITED_WARRANTY',
  BATTERY_SERVICE_AND_WARRANTY: 'BATTERY_SERVICE_AND_WARRANTY'
}
