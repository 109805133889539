import Image from 'react-bootstrap/Image'
import { IModelFigure, IModelYear } from '../../types/IModelFigure'
import { OverlayTrigger, Popover, Col, Row, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { BodyStyled, StyledModelFigure, StyledModelFigureCaption, StyledPopover } from './ModelFigure.styled'
import defaultModel from 'images/bmw-logo-new.png'
import { findModelGroupImage } from 'utils/modelGroupUtils'

export const ModelFigure = ({
  modelType,
  make,
  onRedirect,
  total,
  fuelTypeCategory,
  modelGroupImages,
  bodyStyle,
  ...props
}: IModelFigure) => {
  const [yearFilter, setYearFilter] = useState<IModelYear[]>()
  const [imageURL, setImageURL] = useState<string>()
  const [fuelType, setFuelType] = useState<string>()
  const [showtooltip, setShowtooltip] = useState(false)

  useEffect(() => {
    setYearFilter(props.modelYears)
    const match = findModelGroupImage(modelGroupImages, modelType, bodyStyle, fuelTypeCategory)
    const numberOfImageMatch = modelGroupImages.filter(
      (x) => x.ModelGroup === modelType && x.BodyStyle === match?.BodyStyle
    ).length

    if (numberOfImageMatch > 1) {
      setFuelType(match?.FuelType)
    } else {
      setFuelType(fuelTypeCategory)
    }

    setImageURL(match?.ImageURL)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modelYears, modelType, fuelTypeCategory, bodyStyle])

  return (
    <StyledModelFigure>
      <Col>
        <Row
          className="flex-grow-1 justify-content-center"
          onMouseEnter={() => setShowtooltip(true)}
          onMouseLeave={() => setShowtooltip(false)}
        >
          <OverlayTrigger
            key={`Overlay-${modelType}`}
            placement="bottom"
            show={showtooltip && yearFilter?.length > 0}
            overlay={
              <StyledPopover id="popover-basic">
                <Popover.Content onMouseEnter={() => setShowtooltip(true)} onMouseLeave={() => setShowtooltip(false)}>
                  <div className="car-tooltip-header">
                    <span>{make}</span>
                    <span className="model-type">
                      <strong>{fuelType && make === 'MINI' ? modelType + ' / ' + fuelType : modelType}</strong>
                    </span>
                  </div>
                  <BodyStyled>
                    {yearFilter?.map((yearModel, index) => (
                      <div key={index}>
                        {yearModel.year}&nbsp;
                        <span>
                          <Button
                            variant="link"
                            as={'a'}
                            type={null}
                            onClick={props?.handleRedirectYearAndModelAndFuelType.bind(
                              null,
                              yearModel.year,
                              modelType,
                              fuelTypeCategory
                            )}
                          >
                            <strong>{`(${yearModel.total})`}</strong>
                          </Button>
                        </span>
                      </div>
                    ))}
                  </BodyStyled>
                </Popover.Content>
              </StyledPopover>
            }
          >
            {imageURL ? <Image src={imageURL} /> : <Image src={defaultModel} className="w-50" />}
          </OverlayTrigger>
        </Row>
        <StyledModelFigureCaption className={`figure-caption-model ${imageURL ? '' : 'default-img'}`}>
          {fuelType && make === 'MINI' ? modelType + ' / ' + fuelType : modelType}&nbsp;
          <Button variant="link" as={'a'} type={null} onClick={onRedirect}>
            {`(${total})`}
          </Button>
        </StyledModelFigureCaption>
      </Col>
    </StyledModelFigure>
  )
}
