import { DefaultFilterOptions, DEFAULT_BIG_PAGE_SIZE } from 'common/constants'
import {
  BaseCollectionRequest,
  IBaseCollectionRequest,
  IBaseCollectionResponse,
  IDropdownData,
  IFilterOptions,
  ISortBy,
  Operation,
  SortOrder
} from './baseTypes'
import { IAccountInfo, IPurchaseHistory } from './accountTypes'
import { IAuctionInfo } from './auctionTypes'
import { IUserProfile } from './userTypes'
import { DateTime } from 'luxon'

export interface IVehicleInfo {
  InstanceID: number
  Model: string
  ModelYear: number
  ImageURL: string
  Title: string
  VIN: string
  Mileage: number
  Properties: IVehicleProperties[]
  SaleChannelID: number
  SaleChannelTypeID: number
  SaleEventID: number
  LocationID: number
  SalesSessionStepID: number
  WatchListID: number
  StatusID: number
  BuyItNowPriceString: string
  BuyItNowPrice: number
  FinalPriceString: string
  FinalPrice: number
  OfferID: number
  OfferStatusID: number
  PointBalance: number
  GroundingCompletionDate?: string
  groundingTimeLeft?: string
  StatusDescription: string
  DirectSaleVehicleId: number
  ReserveMet: number
  OfferTag?: string
  SaleChannelName: string
  InteriorTrim: string
  Derivative: string
  ExteriorColour: string
  Images: IVehicleImage[]
  Is360Eligible: boolean
  IsT8Eligible: boolean
  IsWaiveEnabled: boolean
  IsMandatory: boolean
  VehicleNote: string
  NoteId: number
  IsRecentlyAdded: boolean
  DecreasedPrice: boolean
  NewInventory: boolean
  DisclosurePresent: boolean
  DisclosureNote: string
  DisclosureIndicator: boolean
  Incentives: IIncentive[]
  IsIncentiveAccumulate: boolean
  ThumbImageURL: string
  CanBuyItNow: boolean
  Charges: IVehicleCharges[]
  WheelType: string
  Defects: IDefect[]
  SourceCode: string
  IsSellerDisclosure: boolean
  Make: string
  Condition: string
  Transmission: string
  BodyStyle: string
  VehicleLocationCity: string
  LocationAddressDetails: string
  LocationPostCode: string
  EngineSize: string
  EngineDescription: string
  HasAttachments: boolean
  FuelType: string
}

export interface IVehicleCharges {
  ID: number
  VehicleInstanceID: number
  VehicleChargeTypeID: number
  VehicleChargeTypeDescription: string
  IsAddhoc: boolean
  Price: number
  PriceString: string
  VatRate: number
  Vat: number
  VatWithoutCalc: number
  ChargeID: number
  GrossPrice: number
}

export interface IIncentive {
  Name: string
  Amount: number
  AmountString: string
}
export interface IVehicleImage {
  ID: number
  Description: string
  ImageURL: string
  ImageType: string
  LocalImageURL: string
  LocalThumbImageURL: string
  VehicleImageTypeDescription: string
  DamageImageDescription: string
  VehicleDamageID: number
}

export interface IVehicleProperties {
  ID: number
  Code: string
  Description: string
  UIConstantName: string
}
export interface IVehicleData {
  CanBid: boolean
  EndDate: string
  HasPayment: boolean
  IsAvailable: boolean
  accountList: IAccountInfo[]
  auctionInfo?: IAuctionInfo
  curAccount: number
  isVisible: boolean
  vehicle: IVehicleInfo
  notes: string[]
  inspect: IVehicleInspect
  carfaxSnapshotList: ICarFaxSnapshotItem[]
  documentSignInfo: IDocumentSignInfor
  SalesSessionStepId: number
  Recalls: Recall
  uniquePackages: IPackageGroup[]
  uniqueTypes: IBaseList[]
  PurchaseHistory: IPurchaseHistory
}

export interface IBaseList {
  Description: string
  IntegerValue: number
}

export interface IDefect {
  Code: string
  Description: string
  ClaimDate: DateTime
  Mileage: string
}

export interface IPackageGroup {
  Description: string
  Option: string[]
}

export interface IVehicleInspect {
  VehicleDamage: IVehicleDamage[]
  uniquePackages: IPackageGroup[]
  FrameDamage: boolean
  AnnouncedConditions: string
  Comments: string
  AsIs100K: string
  TMUReason: string
  VehicleTyreDetails?: IVehicleTyreInspectionDetail[]

  InspectionDate: string
  BasicWarranty: string
  PowertrainWarranty: string
  NonRunner: boolean
  NumberOfKeys: number
  MasterKeyExists: boolean
  Odor: string
  InspectionCompany: string
}

export interface IVehicleDamage extends IVehicleImage {
  ID: number
  Description: any
  Area: string
  Region: string
  Repaired: boolean
  Chargeable: boolean
  ImageURL: string
  Type: string
}

export class IDamageSummary {
  DamageArea: string
  DamageItems: IDamageItem[]
}

export class IDamageItem {
  ID: number
  DamageDescription: string
  ImageURL: string
  DamageType: string
  IsImageLoaded: boolean
  Repaired: string
  DamageCondition: string
}
export interface IVehicleTyreInspectionDetail {
  ID: number
  VehicleInstanceID: number
  VehicleInspectionID: number
  Description: string
  IsLegal: boolean
  Depth: number
  Make: string
  DOT: string
  NSpec: string
  Size: string
  Specification: string
  CreatedBy: number
  ModifiedBy: number
  CreatedDate: DateTime
  ModifiedDate: DateTime
  IsActive: boolean
}

export interface RecallDetail {
  OpenRecallCampaignID: string
  RecallEffectiveDate: DateTime
  RecallDetails: string
}

export interface Recall {
  RecallDetails: RecallDetail[]
  RecallStatusType: number
}
export interface ISearchVehiclesRequest extends IBaseCollectionRequest {
  QuickSearch: boolean
  ReturnVehicles: boolean
  Ids: number[]
  SearchQuery: SearchQuery
  ReturnZeroCountFilters?: boolean
}

export interface IDeliveryChargeRequest {
  DeliveryQuote: IDeliveryQuote
  InvoiceDealerID: number
  VehicleInstanceId: number
  SalesSessionStepId: number
  QuickBid?: boolean
}

export interface IBidOnVehicleRequest {
  VehicleInstanceId: Number
  BidAmount: Number
  QuickBid: Boolean
  BuyItNowPrice: Number
  SalesSessionStepId: Number
  DeliveryQuote: IDeliveryQuote
  ReferenceNumber: string
  PaymentTypeOther?: string
  ConnectionId: string
}
export interface IVehicleFeeRequest {
  Price: number
  VehicleInstanceId: number
  SaleChannelId: number
  LocationId: number
}
export interface IVehicleFeeResponse {
  ID: number
  Premium: number
  PremiumString: string
  IsRange: boolean
  HasAnyChargeRange: boolean
}

export class GetBaseVehicleRequest extends BaseCollectionRequest {
  Ids: number[]
  IncludeInspectionReport?: boolean
  IncludeBaseData?: boolean
  IncludeAuctionData?: boolean
  VehicleBaseData?: IVehicleInfo[]

  constructor(items: IVehicleData[]) {
    super()
    this.PageSize = items.length
    this.SortBy = {
      Name: 'Title ASC',
      Value: 'Title ASC',
      SortOrder: SortOrder.ASC
    }
    this.IncludeAuctionData = true
    this.Ids = items.map((item) => item.vehicle.InstanceID)
  }
}

export interface IDeliveryQuote {
  ChannelID?: number
  LocationID?: number
  SalesSessionStepId?: number
  VehicleInstanceID: number
  AddressID?: number
  PaymentTypeID?: number
  InterestStatusID?: number
  InterestID?: number
  SalePrice?: number
  TransportCompanyID?: number
  PaymentTypeOther?: string
}

export interface ISearchResponseData {
  FieldId: number
  FieldName: string
  DBFieldName: string
  Value: string
  Value2?: string
  Value3?: string
  SearchCriteriaFieldTypeID: number
  MultiSelect: boolean
  Selected: boolean
  ChildrenFilter: ISearchFilterResult[]
  FilterKey?: string
  VehicleCount?: number
  OrderNumber?: number
  GroupName?: string
  FriendlyValue?: string
  DisplayFriendlyValue?: string
  Operator?: Operation
  verified?: boolean
}

export interface ISearchFilterResult {
  FieldId: number
  FieldName: string
  DBFieldName: string
  FriendlyValue: string
  VehicleCount?: number
  Value: string
  Value2?: string
  Value3?: string
  SelectedItem?: string
  Selected: boolean
  SearchCriteriaFieldTypeID?: number
  ChildrenFilter?: ISearchFilterResult[]
  MultiSelect: boolean
  Operation: Operation
  FilterKey?: string
  CurrentBuyerDefaultPostCode?: string
}

export class SearchQuery {
  ReturnOnlyVehicles: boolean = false
  SearchFilters: ISearchFilterResult[] = []
  SortBy: string[] = []
  StatusIds: string[] = []
}

export class FilterOptions {
  IsReserveMetVehicles: boolean = false
  IsRecentlyAddedVehicles: boolean = false
}

export interface IVehicleSearchDocument {
  BodyStyle: string
  AdditionalBodyStyle: string
  Series: string
  ModelYear: number
  ModelGroup: string
  VehicleInstanceId: number
  FuelType: string
  FuelTypeCategory: string
  Make: string
  IsNewInventory: boolean
}

export interface ISearchVehicleResponse extends IBaseCollectionResponse<IVehicleData> {
  Data: ISearchResponseData[]
}

export interface IDeliveryChargeInfo {
  DisplayCharge: string
  DeliveryCharge?: number
  DeliveryLocation?: string
  HasError?: boolean
}

export class GetBidVehiclesRequest extends BaseCollectionRequest {
  constructor(pageSize?: number, pageNumber?: number, sortBy?: ISortBy, filterOptions?: IFilterOptions) {
    super()
    this.PageSize = pageSize ?? DEFAULT_BIG_PAGE_SIZE
    this.PageNumber = pageNumber ?? 0
    this.SortBy = {
      Name: sortBy?.Name ?? 'Model ASC',
      SortOrder: sortBy?.SortOrder ?? 1,
      Value: sortBy?.Value ?? ' Model ASC'
    }
    this.FilterOptions = filterOptions ?? DefaultFilterOptions
  }
}

export interface IGetBidVehiclesResponse extends IBaseCollectionResponse<IVehicleData> {
  Items: IVehicleData[]
  Data?: ISearchResponseData[]
}

export interface IGetBidsHistoryRequest {
  Id: number
}
export interface IBaseWatchList {
  VehicleInstanceID: number
  SaleChannelID: number
  SaleEventID: number
  ID?: number
  VIN?: string
}
export interface ISaveWatchlistRequest extends IBaseWatchList {
  RemoveVehicle: boolean
}

export interface IGetWatchListResponse extends IBaseCollectionResponse<IBaseWatchList> {
  Items: IBaseWatchList[]
}

export interface ICarFaxSnapshotItem {
  Icon: string
  Value: string
  ValueCount: number
}

export const enum BodyStyles {
  Sedan = 'Sedan',
  Coupe = 'Coupe',
  SAV = 'SAV',
  Convertible = 'Convertible'
}
export interface IDocumentSignInfor {
  DocumentURL: string
  DocumentTypeID: number
  IsAccepted: boolean
  VehicleInstanceDocumentsID: number
  VehicleInstanceDocumentsSignInfoID: number
}
export interface IVehicleNote {
  VehicleInstanceID: number
  NotesId: number
  Notes: string
}

export const enum VehicleAction {
  BID = 'BID',
  BUY = 'BUY'
}

export interface DeliveryInformation {
  PaymentTypes: IDropdownData[]
  TransportProviders: IDropdownData[]
  Contacts: IDropdownData[]
  Countries: IDropdownData[]
  PickUpAddres: string
  PickUpZIP: string
  VehicleInstanceID: number
  AddressID: number
  TransportCompanyID: number
  HasQuote: boolean
  QuotePrice: number
  DeliveryInfo: DeliveryQuote
  QuoteExpiryDate: Date
  DeliveryAddress: DeliveryAddress
  SalesSessionStepID: number
  ContactID: number
  PaymentTypeID: number
  ApplyToPurchase: boolean
  PendingPurchaseID: number
  ChannelID: number
  LocationID: number
  Instructions: string
  TitleDeliveryCharge: number
  BuyerFeeString: string
  PaymentTypeOther: string
  BuyerFee: number
  HasAnyChargeRange: boolean
  UserProfile: IUserProfile
  CurrentBuyerAccountRef: string
  CurrentBuyerAlternateIdentifiers: IDropdownData[]
}
interface DeliveryQuote {
  VehicleInstanceID: number
  PickUpZIP: string
  AddressID: number
  TransportCompanyID: number
  DeliveryAddress: DeliveryAddress
  SalesSessionStepID: number
  ContactID: number
  PaymentTypeID: number
  ApplyToPurchase: boolean
  PendingPurchaseID: number
  ChannelID: number
  LocationID: number
  Instructions: string
}
interface DeliveryAddress {
  AddressLineOne: string
  AddressLineTwo: string
  BuildingNbrNm: string
  Town: string
  CountryId: number
  PostCode: string
  StateId: number
}

export interface SavedSearch {
  ID: number
  NotifyMe: boolean
  Email: string
  EmailFormat: string
  EmailRecurrance: number
  EmailDay: number
  EmailRecurranceDuration: number
  VendorID: number
  Description: string
  UserID: number
  HostID: number
  verified: boolean
}

export interface DropdownData {
  ID: number
  Code: string
  FieldDescription: string
  Description: string
}

export interface SavedSearchData {
  search: SavedSearch
  frequency: DropdownData[]
  durations: DropdownData[]
  days: DropdownData[]
}

export interface SavedSearchReceive {
  filters: ISearchResponseData[]
  searchData: SavedSearch
}

export interface BuyerBaseVehicleSearchCriteria {
  verified: boolean
}

export interface WidgetConfigurationDetails {
  ControlName: string
  ControlDescription: string
  OrderNumber: number
}
