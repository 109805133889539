import { useState, useEffect, useMemo, useReducer } from 'react'
import { HomeSectionHeader } from '../components/Header/HomeSectionHeader'
import { Row, Col } from 'react-bootstrap'
import Collapse from 'components/Collapse'
import { ModelFigure } from 'components/ModelFigure/ModelFigure'
import { useFetch } from 'hooks/useFetch'
import { searchAvailableInventory } from 'apis/vehicleApis'
import { getModelGroupImages } from 'apis/dashboardApis'
import { ISearchFilterResult, IVehicleSearchDocument } from 'types/vehicleTypes'
import {
  StyledHomeSection,
  StyledHomeSectionBody,
  StyledHomeSectionHeaderLink,
  StyledHomeSectionHeaderActionSection,
  StyledRowInventory
} from 'components/Header/HomeSectionHeader.styled'
import { StyledCountingText } from 'modules/AvailableInventory.styled'
import { useExpand } from 'hooks/useExpand'
import {
  AVAILABLE_INVENTORY_HEADING,
  AVAILABLE_INVENTORY_INIT_QUERY,
  AVAILABLE_INVENTORY_MAPPER,
  NO_NEW_INVENTORY_MESSAGE,
  GENERIC_NO_VEHICLE_AVAILABLE_MESSAGE,
  SessionStorageKey
} from 'common/constants'
import { chain, isEmpty, keyBy, merge, values, cloneDeep } from 'lodash'
import {
  hasAnyChilrenFilter,
  isAnyChilrenFiltersSelected,
  processVehicleSearchDocumentResult,
  resetChildrenFilters,
  selectChildrenFilter
} from 'common/helpers'

import { ToggleSwitch } from 'components/Switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { DtmComponentName, getInventoryClickName } from 'utils/analyticsUtils'
import { getFuelTypeBaseOnFuelTypeCategory, getSeriesBasedOnModelGroup } from 'utils/modelGroupUtils'
import { Loader } from 'components/Loader'
import { srpFilters } from 'common/srpFilters'
import { useSrpFiltersStore } from 'store/useSrpFiltersStore'
import shallow from 'zustand/shallow'
import { useDtmAnalytics } from 'hooks/useDtmAnalytics'
import { getSearchUrl } from 'utils/urlUtils'
import { useHistory } from 'react-router-dom'
import { isReactRoutes } from 'common/routes'
import { resetSRPFilter } from 'utils/menuUtils'
import { useGlobalStore } from 'store/useGlobalStore'
import { ISystemSetting } from 'types/baseTypes'

interface IFiltersReducerAction {
  type: 'INIT' | 'UPDATE' | 'RESET_TO_DEFAULT' | 'RESET_ALL'
  payload?: ISearchFilterResult[]
}

const setDefaultFilters = (filters: ISearchFilterResult[]): ISearchFilterResult[] => {
  const filterClone = cloneDeep(filters) as ISearchFilterResult[]
  resetChildrenFilters(filterClone)

  // Set default filters:
  //
  // Body Style: 'Sedan'
  // Make      : 'BMW'
  //
  // Because we only load `HeadingData` once, so these changes only applied
  // when the `HeadingData` have value
  return filterClone.map((filter) => {
    if (filter.FieldName === srpFilters.BodyStyle) {
      const filterValue =
        AVAILABLE_INVENTORY_MAPPER.find(
          (m) => m.DisplayText?.toUpperCase() === AVAILABLE_INVENTORY_HEADING.BodyStyle[0]?.toUpperCase()
        )
          ?.Value?.split(',')
          ?.at(0) ?? AVAILABLE_INVENTORY_HEADING.BodyStyle[0]

      if (filter.ChildrenFilter.find((item) => item.Value?.toUpperCase() === filterValue?.toUpperCase()))
        filter.ChildrenFilter.map((item) => {
          if (item.Value?.toUpperCase() === filterValue?.toUpperCase()) {
            item.Selected = true
          }
          return item
        })
    }

    if (filter.FieldName === srpFilters.Make) {
      const filterValue =
        AVAILABLE_INVENTORY_MAPPER.find((m) => m.DisplayText === AVAILABLE_INVENTORY_HEADING.Make[0])?.Value ??
        AVAILABLE_INVENTORY_HEADING.Make[0]

      if (filter.ChildrenFilter.find((item) => item.Value === filterValue))
        filter.ChildrenFilter.find((item) => item.Value === filterValue)!.Selected = true
    }

    return filter
  })
}

const filtersReducer = (state: ISearchFilterResult[], action: IFiltersReducerAction): ISearchFilterResult[] => {
  switch (action.type) {
    case 'INIT':
      const initFilters = cloneDeep([...action.payload]) as ISearchFilterResult[]
      selectChildrenFilter(initFilters, srpFilters.NewInventory, 'true')
      return initFilters
    case 'UPDATE':
      return values(merge(keyBy(state, 'DBFieldName'), keyBy(action.payload, 'DBFieldName')))
    case 'RESET_TO_DEFAULT':
      const takenFilters = action.payload ?? state
      return setDefaultFilters([...takenFilters])
    case 'RESET_ALL':
      resetChildrenFilters(state)
      return state
    default:
      return state
  }
}

interface IProps {
  isFilterLoading: boolean
}

export const AvailableInventory = ({ isFilterLoading: HeadingLoading }: IProps) => {
  const { state: expandState, action: expandAction } = useExpand({ componentOpen: true } as never)
  const [displayFilters, setDisplayFilters] = useState(() => AVAILABLE_INVENTORY_HEADING)
  const [filters, dispatchFilters] = useReducer(filtersReducer, null)
  const [currentFuelType, setCurrentFuelType] = useState('')
  const [isNewInventoryView, setIsNewInventoryView] = useState(true)
  const [currentBodyStyle, setCurrentBodyStyle] = useState<string>(null)
  const history = useHistory()

  const { getSystemSetting } = useGlobalStore()
  const electricSetting = getSystemSetting(
    AVAILABLE_INVENTORY_MAPPER.find((m) => m.DisplayText === 'Fully Electric')?.Value
  ) as ISystemSetting
  const hybridSetting = getSystemSetting(
    AVAILABLE_INVENTORY_MAPPER.find((m) => m.DisplayText === 'Hybrids')?.Value
  ) as ISystemSetting
  const electricFuelTypes = electricSetting.ValueString?.split(',')
  const hybridFuelTypes = hybridSetting.ValueString?.split(',')

  const { data: modelGroupImages = [] } = useFetch(() => getModelGroupImages())

  const { filters: HeadingData, totalVehicles: TotalRecords } = useSrpFiltersStore((state) => state, shallow)

  const { loading: ContentLoading, data: ContentData } = useFetch(() =>
    searchAvailableInventory({ ...AVAILABLE_INVENTORY_INIT_QUERY })
  )

  const { componentOpen } = expandState
  const { setExpand } = expandAction
  const { userInteraction, endComponentLoad } = useDtmAnalytics()

  useEffect(() => {
    if (!ContentLoading) {
      endComponentLoad(DtmComponentName.DashboardInventory)
    }
  }, [ContentLoading, endComponentLoad])

  // Set default filters
  useEffect(() => {
    if (HeadingLoading || !HeadingData) return

    const payload = [...HeadingData] as ISearchFilterResult[]

    if (hasAnyChilrenFilter(payload, srpFilters.NewInventory)) {
      dispatchFilters({ type: 'INIT', payload: payload })
    } else {
      dispatchFilters({ type: 'RESET_TO_DEFAULT', payload: payload })
      setIsNewInventoryView(false)
    }
  }, [HeadingLoading, HeadingData])

  // Use to update filters show on UI
  useEffect(() => {
    if (ContentLoading || !ContentData) return

    setDisplayFilters((ft) => ({ ...filterEmptyResultSearchFilters(ft, ContentData) }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContentLoading, ContentData])

  const filterEmptyResultSearchFilters = (filters: Record<string, string[]>, ContentData: IVehicleSearchDocument[]) => {
    Object.entries(filters).forEach(([key, values]) => {
      filters[key] = [
        ...values.filter((v) => {
          // 1. Get original value (if have)
          const mappingValue = AVAILABLE_INVENTORY_MAPPER.find((m) => m.DisplayText === v)?.Value
          const originalFuelTypes = v === 'Fully Electric' ? electricFuelTypes : v === 'Hybrids' ? hybridFuelTypes : []
          const splittedValues = key === 'FuelType' ? originalFuelTypes : (mappingValue ?? v).split(',')

          // 2. Filters from ContentData to check if we have any items
          return splittedValues?.some((v) =>
            ContentData.filter((d) => d.Make === (key === srpFilters.Make ? d.Make : 'BMW')).some((d) => d[key] === v)
          )
        })
      ]
    })

    return filters
  }

  const bodyStyleFilters = useMemo(() => filters?.find((item) => item.FieldName === srpFilters.BodyStyle), [filters])
  const additionalBodyStyleFilters = useMemo(
    () => filters?.find((item) => item.FieldName === srpFilters.AdditionalBodyStyle),
    [filters]
  )
  const makeFilters = useMemo(() => filters?.find((item) => item.FieldName === srpFilters.Make), [filters])

  const modelGroupFilters = useMemo(() => {
    if (!filters || !ContentData) return

    let result = [...ContentData]

    result = result.map((value) => {
      if (electricFuelTypes?.some((ft) => ft === value?.FuelType)) {
        return { ...value, FuelTypeCategory: 'Fully Electric' }
      } else if (hybridFuelTypes?.some((ft) => ft === value?.FuelType)) {
        return { ...value, FuelTypeCategory: 'Hybrids' }
      } else if (value?.FuelType !== '' && value?.FuelType !== undefined) {
        return { ...value, FuelTypeCategory: 'Gasoline' }
      } else return { ...value, FuelTypeCategory: 'undefined' }
    })

    if (!isNewInventoryView) {
      // Filters for Available Inventory View

      // 1. Check 'Body Style' filter
      result = processVehicleSearchDocumentResult(result, filters, srpFilters.BodyStyle, (r) => r.BodyStyle)

      // 1.1. Check 'Additional Body Style' filter
      result = processVehicleSearchDocumentResult(
        result,
        filters,
        srpFilters.AdditionalBodyStyle,
        (r) => r.AdditionalBodyStyle
      )

      // 2. Check 'Make' filter
      result = processVehicleSearchDocumentResult(result, filters, srpFilters.Make, (r) => r.Make)

      // 3. Check 'Fuel Type' filter
      if (currentFuelType !== '') {
        if (isAnyChilrenFiltersSelected(filters, srpFilters.FuelType)) {
          result = processVehicleSearchDocumentResult(result, filters, srpFilters.FuelType, (r) => r.FuelType)
        } else {
          // Remove all results as we don't have any filters match selected fuel type
          // This will make the list empty and the UI will render 'No vehicle available' message
          result = []
        }
      }
    } else {
      // Filters for New Inventory View

      // Find all vehicle with New Inventory fitlers
      result = result.filter((r) => r.IsNewInventory)
    }

    // 4. Transform & Return result
    return Object.entries(chain(result).orderBy('ModelGroup').groupBy('ModelGroup').value())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContentData, currentFuelType, filters, isNewInventoryView])

  const handleChangeBodyStyleFilters = (bodyStyle: string) => {
    // Reset flag for Fuel Type
    setCurrentFuelType('')

    setIsNewInventoryView(false)
    resetChildrenFilters(filters)

    const originalValues = AVAILABLE_INVENTORY_MAPPER.find((m) => m.DisplayText === bodyStyle)?.Value?.split(',')
    if (
      originalValues &&
      originalValues.some((value) =>
        filters.find((q) => q.FieldName === srpFilters.BodyStyle)?.ChildrenFilter?.some((cft) => cft.Value === value)
      )
    ) {
      selectChildrenFilter(filters, srpFilters.BodyStyle, ...originalValues)
    } else {
      selectChildrenFilter(filters, srpFilters.BodyStyle, bodyStyle)
    }

    setCurrentBodyStyle(bodyStyle)
    selectChildrenFilter(filters, srpFilters.Make, 'BMW')

    dispatchFilters({ type: 'UPDATE', payload: filters })
    userInteraction(`Dashboard:InventoryView: All: ${bodyStyle}`)
  }

  const handleChangeAdditionalBodyStyleFilters = (additionalBodyStyle: string) => {
    // Reset flag for Fuel Type
    setCurrentFuelType('')

    setIsNewInventoryView(false)
    resetChildrenFilters(filters)
    selectChildrenFilter(filters, srpFilters.AdditionalBodyStyle, additionalBodyStyle)
    setCurrentBodyStyle(additionalBodyStyle)
    selectChildrenFilter(filters, srpFilters.Make, 'BMW')

    dispatchFilters({ type: 'UPDATE', payload: filters })
    userInteraction(`Dashboard:InventoryView: All: ${additionalBodyStyle}`)
  }

  const handleChangeFuelTypeFilters = (fuelType: string) => {
    // 0. Reset values
    setIsNewInventoryView(false)
    resetChildrenFilters(filters)
    setCurrentBodyStyle(null)
    selectChildrenFilter(filters, srpFilters.Make, 'BMW')

    // 1. Convert value to original value (using MAPPER)
    const originalFuelTypes =
      fuelType === 'Fully Electric' ? electricFuelTypes : fuelType === 'Hybrids' ? hybridFuelTypes : []

    // 2. If Update query with selected fuel types
    if (
      originalFuelTypes &&
      originalFuelTypes.some((value) =>
        filters.find((q) => q.FieldName === srpFilters.FuelType)?.ChildrenFilter?.some((cft) => cft.Value === value)
      )
    ) {
      selectChildrenFilter(filters, srpFilters.FuelType, ...originalFuelTypes)
    } else {
      selectChildrenFilter(filters, srpFilters.FuelType, fuelType)
    }

    dispatchFilters({ type: 'UPDATE', payload: filters })

    // Set Fuel Type flag
    setCurrentFuelType(fuelType)
    userInteraction(`Dashboard:InventoryView: All: ${fuelType}`)
  }

  const handleChangeMakeFilters = (make: string) => {
    setIsNewInventoryView(false)
    // Reset flag for Fuel Type
    setCurrentFuelType('')
    setCurrentBodyStyle(null)

    resetChildrenFilters(filters)
    selectChildrenFilter(filters, srpFilters.Make, make)

    dispatchFilters({ type: 'UPDATE', payload: filters })
    userInteraction(`Dashboard:InventoryView: ${isNewInventoryView ? 'New' : 'All'}: ${make}`)
  }

  const handleInventoryViewToggle = (isChecked: boolean) => {
    /*
     * isChecked == true  => New Inventory
     * isChecked == false => Available Inventory
     */

    if (isChecked) {
      // 1. Reset all filters
      setCurrentFuelType('') // Reset flag for Fuel Type
      setCurrentBodyStyle(null)

      resetChildrenFilters(filters)
      selectChildrenFilter(filters, srpFilters.NewInventory, 'true')

      dispatchFilters({ type: 'UPDATE', payload: filters })
    } else {
      dispatchFilters({ type: 'RESET_TO_DEFAULT' })
    }

    setIsNewInventoryView(isChecked)
    userInteraction(`Dashboard:InventoryView: ${isChecked ? 'New' : 'All'}`)
  }

  const handleRedirect = (ft?: ISearchFilterResult[]) => {
    resetSRPFilter()
    sessionStorage.setItem(SessionStorageKey.SEARCH_FILTERS, ft ? JSON.stringify(ft) : null)
    const customClickName = `Dashboard:InventoryView: ${isNewInventoryView ? 'New' : 'All'}${getInventoryClickName(ft)}`
    userInteraction(customClickName)

    if (isReactRoutes(getSearchUrl())) history.push(getSearchUrl(), ft)
    else window.location.href = getSearchUrl()
  }

  const handleViewAllVehicles = () => {
    handleRedirect(null)
  }

  const handleRedirectModelGroup = (modelType: string, fuelTypeCategory: string) => {
    resetChildrenFilters(filters, srpFilters.Series)
    selectChildrenFilter(filters, srpFilters.Series, ...getSeriesBasedOnModelGroup(modelGroupFilters, modelType))
    selectChildrenFilter(
      filters,
      srpFilters.FuelType,
      ...getFuelTypeBaseOnFuelTypeCategory(modelGroupFilters, modelType, fuelTypeCategory)
    )

    handleRedirect(filters)
  }

  const handleRedirectYearAndModelGroupAndFuelType = (
    modelYear: string,
    modelType: string,
    fuelTypeCategory: string
  ) => {
    resetChildrenFilters(filters, srpFilters.Series, srpFilters.Year, srpFilters.FuelType)
    selectChildrenFilter(filters, srpFilters.Series, ...getSeriesBasedOnModelGroup(modelGroupFilters, modelType))
    selectChildrenFilter(filters, srpFilters.Year, modelYear)
    selectChildrenFilter(
      filters,
      srpFilters.FuelType,
      ...getFuelTypeBaseOnFuelTypeCategory(modelGroupFilters, modelType, fuelTypeCategory)
    )

    handleRedirect(filters)
  }

  const renderModelFigure = (modelGroup, arr, fuelTypeCategory = null) => (
    <ModelFigure
      key={`${modelGroup}_${arr}`}
      modelType={modelGroup}
      bodyStyle={currentBodyStyle}
      modelGroupImages={modelGroupImages}
      fuelTypeCategory={fuelTypeCategory}
      make={arr[0].Make}
      modelYears={Object.entries(chain(arr).orderBy('ModelYear').countBy('ModelYear').value()).map(([year, count]) => ({
        year,
        total: count
      }))}
      total={arr?.length}
      onRedirect={handleRedirectModelGroup.bind(null, modelGroup, fuelTypeCategory)}
      handleRedirectYearAndModelAndFuelType={handleRedirectYearAndModelGroupAndFuelType}
    />
  )

  return (
    <StyledHomeSection>
      <HomeSectionHeader
        sectionTitle="Available Inventory"
        onClick={() => {
          setExpand(!componentOpen)
          userInteraction(`Dashboard:InventoryView: AvailableInventory: ${componentOpen ? 'Collapse' : 'Expand'}`)
        }}
        isExpand={componentOpen}
        leftActions={
          !ContentLoading &&
          !HeadingLoading &&
          ContentData?.length !== 0 && (
            <>
              <StyledHomeSectionHeaderActionSection className="d-flex">
                <ToggleSwitch
                  label="Inventory View"
                  showIcon
                  statusText={{
                    left: 'ALL',
                    right: 'NEW'
                  }}
                  checked={isNewInventoryView}
                  onToggle={handleInventoryViewToggle}
                ></ToggleSwitch>
              </StyledHomeSectionHeaderActionSection>
              <StyledHomeSectionHeaderActionSection className="d-flex">
                {displayFilters.BodyStyle.map((bodyStyle, idx) => (
                  <StyledHomeSectionHeaderLink
                    key={idx}
                    onClick={handleChangeBodyStyleFilters.bind(null, bodyStyle)}
                    active={
                      bodyStyleFilters?.ChildrenFilter?.find((cf) =>
                        AVAILABLE_INVENTORY_MAPPER.find(
                          (m) => m.DisplayText?.toUpperCase() === bodyStyle?.toUpperCase()
                        )?.Value?.includes(cf.Value)
                      )?.Selected
                    }
                  >
                    <span>{bodyStyle}</span>
                  </StyledHomeSectionHeaderLink>
                ))}
                {displayFilters.AdditionalBodyStyle.map((bodyStyle, idx) => {
                  /* 1. Get original value */
                  const originalValue =
                    AVAILABLE_INVENTORY_MAPPER.find((m) => m.DisplayText === bodyStyle)?.Value ?? bodyStyle

                  /* 2. Check with resp to make sure the style exist */
                  return (
                    additionalBodyStyleFilters?.ChildrenFilter?.some((cf) => cf.Value === originalValue) && (
                      <StyledHomeSectionHeaderLink
                        key={idx}
                        onClick={handleChangeAdditionalBodyStyleFilters.bind(null, originalValue)}
                        active={
                          additionalBodyStyleFilters?.ChildrenFilter?.find((cf) => cf.Value === originalValue)?.Selected
                        }
                      >
                        <span>{bodyStyle}</span>
                      </StyledHomeSectionHeaderLink>
                    )
                  )
                })}
              </StyledHomeSectionHeaderActionSection>
              <StyledHomeSectionHeaderActionSection className="d-flex">
                {displayFilters.FuelType.map((type, index) => (
                  <StyledHomeSectionHeaderLink
                    key={index}
                    onClick={handleChangeFuelTypeFilters.bind(null, type)}
                    active={currentFuelType === type}
                  >
                    <span>{type}</span>
                  </StyledHomeSectionHeaderLink>
                ))}
                {displayFilters.Make.filter((ft) => ft !== 'BMW')?.map((type, index) => (
                  <StyledHomeSectionHeaderLink
                    key={index}
                    onClick={handleChangeMakeFilters.bind(null, type)}
                    active={makeFilters?.ChildrenFilter?.find((cf) => cf.Value === type)?.Selected}
                  >
                    <span>{type}</span>
                  </StyledHomeSectionHeaderLink>
                ))}
              </StyledHomeSectionHeaderActionSection>
            </>
          )
        }
        rightActions={
          !(HeadingLoading || ContentLoading) && (
            <StyledHomeSectionHeaderActionSection className="d-flex">
              <StyledHomeSectionHeaderLink onClick={handleViewAllVehicles}>
                View All Vehicles&nbsp;<StyledCountingText>({TotalRecords ?? 0})</StyledCountingText>
              </StyledHomeSectionHeaderLink>
            </StyledHomeSectionHeaderActionSection>
          )
        }
      />
      <Collapse in={componentOpen}>
        <StyledHomeSectionBody>
          {(ContentLoading || HeadingLoading) && <Loader overlay />}
          {!ContentLoading && !HeadingLoading && isEmpty(modelGroupFilters) && (
            <Row>
              <Col className="d-flex flex-column justify-content-center align-items-center py-5 text-secondary">
                <FontAwesomeIcon icon={faCar} className="fa-4x" />
                <div>{isNewInventoryView ? NO_NEW_INVENTORY_MESSAGE : GENERIC_NO_VEHICLE_AVAILABLE_MESSAGE}</div>
              </Col>
            </Row>
          )}

          {!ContentLoading && !HeadingLoading && !isEmpty(modelGroupFilters) && (
            <StyledRowInventory>
              {modelGroupFilters
                ?.filter(([mg, _]) => mg !== 'undefined')
                .map(([modelGroup, arrOfSeries]) => {
                  const groupedByFuelTypeCategory = Object.entries(
                    chain(arrOfSeries).groupBy('FuelTypeCategory').value()
                  )
                  const shouldGroupByFuelTypeCategory = arrOfSeries[0].Make === 'MINI'

                  return shouldGroupByFuelTypeCategory
                    ? groupedByFuelTypeCategory
                        .filter(([ct, _]) => ct !== 'undefined')
                        .map(([fuelTypeCategory, arr]) =>
                          renderModelFigure(
                            modelGroup,
                            arr,
                            groupedByFuelTypeCategory.length > 1 ? fuelTypeCategory : null
                          )
                        )
                    : renderModelFigure(modelGroup, arrOfSeries, currentFuelType)
                })}
            </StyledRowInventory>
          )}
        </StyledHomeSectionBody>
      </Collapse>
    </StyledHomeSection>
  )
}
