import { IModelGroupImages } from 'types/IModelFigure'
import { BodyStyles, IVehicleSearchDocument } from 'types/vehicleTypes'
import { MODEL_GROUP_MAPPER } from 'common/constants'
import { uniq } from 'lodash'

export const findModelGroupImage = (
  modelGroupImages: IModelGroupImages[],
  modelGroup: string,
  bodyStyle?: string,
  fuelTypeCategory?: string
) => {
  var groupImages = modelGroupImages?.filter((image) => image.ModelGroup === modelGroup)
  if (!groupImages?.length) {
    return
  }

  let match: IModelGroupImages

  if (fuelTypeCategory) {
    match = groupImages.find(
      (image) =>
        image.FuelType ===
        (MODEL_GROUP_MAPPER.find((m) => m.DisplayText.toLowerCase() === fuelTypeCategory.toLowerCase())?.Value ??
          fuelTypeCategory)
    )
  }

  if (!match && bodyStyle) {
    match = groupImages.find(
      (image) =>
        image.BodyStyle ===
        (MODEL_GROUP_MAPPER.find((m) => m.DisplayText.toLowerCase() === bodyStyle.toLowerCase())?.Value ?? bodyStyle)
    )
  }

  if (!match) {
    // Default to Sedan if not found, or return first image of the group
    match = groupImages.find((image) => image.BodyStyle === BodyStyles.Sedan) || groupImages[0]
  }

  return match
}

export const getSeriesBasedOnModelGroup = (
  modelGroupFilters: [string, IVehicleSearchDocument[]][],
  modelGroupName: string
) => {
  const filter = modelGroupFilters.find(([modelGroup, _]) => modelGroup === modelGroupName)
  if (!filter) return []

  return uniq((filter[1] as IVehicleSearchDocument[]).map((d) => d.Series))
}

export const getFuelTypeBaseOnFuelTypeCategory = (
  modelGroupFilters: [string, IVehicleSearchDocument[]][],
  modelGroupName: string,
  fuelTypeCategory: string
) => {
  const arrayOfSeries = modelGroupFilters.find(([modelGroup, _]) => modelGroup === modelGroupName)[1]
  // eslint-disable-next-line array-callback-return
  const fuelTypes = arrayOfSeries.map((value) => {
    if (value.FuelTypeCategory === fuelTypeCategory) return value.FuelType
  })
  if (!fuelTypes) return []

  return fuelTypes
}
